.formControl {
  padding: 0 16px 16px;
  width: 90%;
}

.app {
  padding-top: 16px;
  padding-left: 17px;
  padding-right: 23px;
}

.toolbar {
  background-color: #343e48;
  color: #ffffff;
}

.titleWrap {
  line-height: 2;
}

.button {
  margin: 0 10px;
}

.primaryButton {
  &:hover {
    background-color: #007aff;
  }
  background-color: #007aff;
  color: #ffffff;
}

.error {
  color: #f44336;
  margin: 8px 0 0 0;
  font-size: 0.75rem;
  line-height: 1em;
}

.backButton {
  vertical-align: middle;
  padding-right: 10px;
  margin-left: -12px;
}

.divider {
  border-radius: 2px;
}

.ddlFC {
  width: 100%;
}

.validatorContainer {
  width: 80%;
  margin-right: 19px;
  padding-top: 8px;
  height: 50px;
}

.dropdown {
  flex: auto;
  min-width: 230px;
  margin: 0 20px 0 0;
  white-space: nowrap;
}

.mainGrid {
  padding: 70px 40px;
}

.headerPullRight {
  margin-left: auto;
}

.formField {
  position: relative;
  margin-bottom: 20px !important;
  width: 95% !important;
  max-width: 400px !important;
}

.stack {
  padding: 5px 0px;
  height: 45px;
}

.dateFeild {
  width: 400px !important;
}

.paddingTop {
  padding-top: 16px;
}

.textField {
  width: 120px;
  padding-left: 1px;
}

.flexDisplay {
  display: flex;
}

.loader {
  display: flex;
  align-items: center;
}

.centerText {
  text-align: center;
}