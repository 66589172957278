.hide {
    display: none;
}

.loader {
    height: 100%;
}

.header {
    text-align: left;
}